import axios from 'axios';

//let baseUrl = process.env.VUE_APP_BASEURL;
//console.log("11" + baseUrl)
//创建一个axios对象出来
const request = axios.create({
    //baseURL: "http://localhost:8089/api/",
    //baseURL: "http://43.143.214.173:8089/api/",
    baseURL: "https://www.lscarlive.com/api/api",
   //baseUrl:baseUrl,
    timeout: 5000
})

//request拦截器
//可以自请求发送前对请求做一些处理
//比如统一加token，对请求参数统一加密
   //存cookie中也可以
request.interceptors.request.use(config =>{
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    
 
    const user = localStorage.getItem("user");
    console.log(user);
    if(user) {
        config.headers['token'] = JSON.parse(user).token;
    }
  //  config.headers['token'] =  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0IiwiZXhwIjoxNzExMzgxNTc4fQ.Fah6rVWgAqJNoTYP6Fc0-qe7851y4EdRpoXmtmgseIQ";


    return config
},error => {
    return Promise.reject(error)
});

//response拦截器
//可以在接口响应后统一处理结果
request.interceptors.response.use(
    response =>{
        //response.data即为后端返回的Result
        let res = response.data;
        //兼容服务端返回的字符串数据
        if (typeof res === 'string'){
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' +error) //for debug
        return Promise.reject(error)
    }
)




export default request