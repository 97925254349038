 <template>
   <div>
      <div>
       <el-input v-model="params.name" style="width:200px" placeholder="操作内容"></el-input>
       <el-input v-model="params.username" style="width:200px; margin-left:5px" placeholder="操作人"></el-input>
       <el-button type="primary" style="margin-left: 10px" @click="findBySreach()">查询</el-button>
       <el-button type="primary" style="margin-left: 10px" @click="reset()">清空</el-button>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%"> 
          <el-table-column prop="name" label="操作内容"></el-table-column>
          <el-table-column prop="time" label="操作时间"></el-table-column>
          <el-table-column prop="username" label="操作人" ></el-table-column>
          <el-table-column prop="ip" label="ip" ></el-table-column>
          <el-table-column label="操作">            
          </el-table-column> 
        </el-table>
      </div>
      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-sizes="[10, 20,40, 60]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>    
  
   </div>   
  </template>



<script>
import request from "@/utils/request";

export default {
      //name: "AdminView",
      data() {
        return {
          params: {
            name: '',
            phone: '',
            sex: '',
            pageNum: 1,
            pageSize: 10
          },
          tableData: [],
          total: 0,
          form: {}
        }
      },
      //页面加载的时候，做的一些事情，在created里面
      created(){
        this.findBySreach();
      },
      //定义一些页面上空间触发的事件调用的方法
      methods: {
        findBySreach(){
            request.get("/log/search",{
              params:this.params
            }).then(res =>{
              if(res.code === '0'){
                this.tableData = res.data.list;
                this.total =res.data.total;
              }else{
                this.$message({
                message: res.msg,
                type: 'error'
              });
              }
            })
          },
        reset(){
          this.params ={
            pageNum: 1,
            pageSize: 2,
            name: '',
            phone: '',
          }
          this.findBySreach();
        },
        
      }
    }
  </script>
