import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import Layout from '@/views/Layout.vue'
import RegisterView from '@/views/RegisterView.vue'
import BookView from '../views/BookView.vue'
import TypeView from '../views/TypeView.vue'
import AuditView from '../views/AuditView.vue'
import HotelView from '../views/HotelView.vue'
import ReserveView from '../views/ReserveView.vue'
import LogView from '../views/LogView.vue'
import NoticeView from '../views/NoticeView.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,

    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/AdminView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: '/book',
        name: 'book',
        component: BookView
      },
      {
        path: '/type',
        name: 'type',
        component: TypeView
      },
      {
        path: '/audit',
        name: 'audit',
        component: AuditView
      },
      {
        path: '/hotel',
        name: 'hotel',
        component: HotelView
      },
      {
        path: '/reserve',
        name: 'reserve',
        component: ReserveView
      },
      {
        path: '/log',
        name: 'log',
        component: LogView
      },
      {
        path: '/notice',
        name: 'notice',
        component: NoticeView
      },
    ]
  },    
]

const router = new VueRouter({
  mode: 'history',
  base:process.env.BASE_URL,
  routes
})


//路由守卫
// router.beforeEach((to, from, next) => {
//   if(to.path === '/login') {
//     next();
//   }
//   const user = localStorage.getItem("user");
//   if(!user && to.path !== '/login') {
//     return next('/login');
//   }
//   next();

// })

router.beforeEach((to, from, next) => {

  const user = localStorage.getItem("user");
  if(!user && to.path !== '/login' && to.path !== '/register') {
    return next('/login');
  }
  next();

})
export default router
