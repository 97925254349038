<template>
  <div class="loginback">
    <div style="width:400px; height:400px;margin:150px auto; background-color:rgba(107,149,224,0.5); border-radius:10px">
    <div style ="width:100%;height:100px;font-size:30px;line-height:100px; text-align:center; color:#4a5ed0">欢迎登录</div>
    <div style="margin-top:25px; text-align:center;height:320px">
      <el-form :model="admin">
        <el-form-item>
          <el-input v-model="admin.name" prefix-ioc="el-ico-user" style="width:80%" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="admin.password" show-password prefix-ioc="el-ico-lock" style="width:80%" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex; justify-content: center">
            <el-input v-model="admin.verCode" prefix-ico="el-icon-user" style="width: 42%; margin-right: 10px" placeholder="请输入验证码"></el-input>
            <img :src="captchaUrl" @click="clickImg()" width="140px" height="33px"/>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button  style="width:80%; margin-top: 10px" type="primary" @click="login()">登录</el-button>
        </el-form-item>
        
        <div style="text-align:center">
        未有账号？点击<a href="javascript:void(0)" style="text-decoration: none" @click="navRe()">注册</a>
        </div>
      </el-form>
      </div>

         
      <div style="display:flex;justify-content:center;align-items:center;">
       <!-- <img src="上传到WordPress的备案公安图标地址">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=公安备案号数字
        " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" rel="noopener">
          <img src="" style="float:left;">
          上面查到的网站联网备案号直接复制粘贴到这，包括汉字部分
        </a>
        <span style="margin:0 10px">|</span> -->
        <a target="_blank" href="http://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" rel="noopener">
          京ICP备2024064940号-1
        </a>
      </div>



      </div>
      

       
  
         
          
   
      
  </div>
</template>




<script>
import request from "@/utils/request";

export default {
  name: "Login",
  data(){
    return {
      admin: {},
      key: '',
      captchaUrl: '',

    }
  },
  mounted(){
     this.key = Math.random(),
     this.captchaUrl = this.$baseUrl + '/captcha?key=' + this.key;
  },
  methods: {
    login(){
      request.post("/admin/login?key="+this.key, this.admin).then(res =>{
        if(res.code === '0'){
          this.$message({
                message: '登录成功',
                type: 'success'
              });
              localStorage.setItem("user", JSON.stringify(res.data));
              this.$router.push("/");
        }else{
           this.$message({
                message: res.msg,
                type: 'error'
              });
           this.key = Math.random(),
           this.captchaUrl = this.$baseUrl+'/captcha?key=' + this.key;
        }
      })

    },
    clickImg() {
           this.key = Math.random(),
           this.captchaUrl = this.$baseUrl+'/captcha?key=' + this.key;
    },
    navRe(){
      console.log("aaa"),
      this.$router.push('/register')

    },

  }
}




</script>

<style scoped>
.loginback{
  height :775px;
  overflow:hidden;
  display: flex;
  align-item: center;
  justify-content:center;
}

.aaa{
  background-image:url("@/assets/login_bg.jpg");
  background-size:100%;
}


</style>