<template>
 <div>
    <div style="display:flex">
      <div style="flex: 1">
        <div style="margin-bottom: 15px; font-weight: blod; font-size: 18px">系统公告</div>
          <div style="width:80%">
            <el-collapse v-model="activeName" accordion>
            <el-collapse-item v-for="item in data" :title="item.name" :name="item.id">
              <div style="padding: 0 20px">{{item.content}}</div>
            </el-collapse-item>
            </el-collapse>
        </div>
      </div>
        
        <div style="flex: 1; margin-top:50px">
          <div id="bie" style="width:100%;height:400px"></div>
        </div>
  </div>

  <div style="display:flex">
      <div style="flex:1; margin-top:50px">
      <div id="bar" style="width:100%;height:400px"></div>
      </div>
      <div style="flex:1; margin-top:50px">
        <div id="line" style="width:100%;height:400px"></div>
      </div>
  </div>

 
 

 </div>
</template>


<script>
import request from "@/utils/request";
import * as echarts from 'echarts';

export default {
  name: 'HomeView',

   data() {
      return {
        activeName: '1',
        data: []
      };
    },
  mounted() {
    this.findNotice();   
    this.initEcharts();
  },
  methods: {
    findNotice() {
      request.get("/notice").then(res => {
         if(res.code === '0'){
          this.data = res.data;
          this.activeName = res.data[0].id;

        }else{
          this.$message.error(res.msg)
        }
      })
    },
    initEcharts(){
       request.get("/book/echarts/bie").then(res =>{
        if (res.code === '0'){
          this.initBie(res.data)
        }else{
          this.$message.error(res.msg)
        }
       })
       request.get("/book/echarts/bar").then(res =>{
         if (res.code === '0'){
          let map = res.data
          console.log(map);
          this.initBar(map.xx, map.yy)
          this.initLine(map.xx, map.yy)
        }else{
          this.$message.error(res.msg)
        }
       })
    },
    initBie(data){
      console.log(data)
      let chartDom = document.getElementById('bie');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        title: {
          text: '图书统计',
          subtext: '统计维度：图书分类',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '图书分类',
            type: 'pie',
            radius: '50%',
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
    },
    initBar(x, y){
        let chartDom = document.getElementById('bar');
        let myChart = echarts.init(chartDom);
        let option;

        option = {
        title: {
          text: '图书统计',
          subtext: '统计维度：图书分类',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: y,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    initLine(x, y){
      var chartDom = document.getElementById('line');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: '图书统计',
          subtext: '统计维度：图书分类',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: x
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: y,
            type: 'line'
          }
        ]
      };

      option && myChart.setOption(option);
    },




  }
}

</script>