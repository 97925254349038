 <template>
   <div>
      <div>
       <el-input v-model="params.name" style="width:200px" placeholder="请输入酒店名称"></el-input>
       <el-button type="primary" style="margin-left: 10px" @click="findBySreach()">查询</el-button>
       <el-button type="primary" style="margin-left: 10px" @click="reset()">清空</el-button>
       <el-button tyoe="warning" style="margin-left: 10px" @click="add()" v-if="user.role !== 'ROLE_STUDENT' ">新增</el-button> 
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%"> 
          <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
          <el-table-column prop="userName" label="预订人" ></el-table-column> 
          <el-table-column prop="time" label="预定时间" ></el-table-column> 
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-popconfirm title="这是一段内容确定删除吗？" @confirm="del(scope.row.id)">
              <el-button slot="reference" type="danger" style="margin-left:5px">删除</el-button>
              </el-popconfirm>
            </template>
             
          </el-table-column> 
        </el-table>
      </div>
      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-sizes="[5, 10]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
     


  
   </div>   
  </template>



<script>
import request from "@/utils/request";

export default {
      name: "BookView",
      data() {
        return {
          params: {
            name: '',
            author: '',
            pageNum: 1,
            pageSize: 5
          },
          tableData: [],
          total: 0,
          dialogFormVisible: false,
          form: {},
          user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
        }
      },
      //页面加载的时候，做的一些事情，在created里面
      created(){
        this.findBySreach();
      },
      //定义一些页面上空间触发的事件调用的方法
      methods: {
        findBySreach(){
            request.get("/reserve/search",{
              params:this.params
            }).then(res =>{
              if(res.code === '0'){
                this.tableData = res.data.list;
                this.total =res.data.total;
              }else{
                this.$message.error(res.msg)
              }
            })
          },
        add(){
          this.form = {},
          this.dialogFormVisible = true
        },
        reset(){
          this.params ={
            pageNum: 1,
            pageSize: 5,
            name: '',
            phone: '',
          }
          this.findBySreach();
        },
        del(id){
          console.log(id);
          request.delete("/reserve/"+ id).then(res => {
            if(res.code === '0'){
              this.$message.success('操作成功')
              this.findBySreach();
            }else{
             this.$message.error(res.msg)
            }
          })
        },
       

      
        
      }
    }
  </script>
