 <template>
   <div>
      <div>
       <el-input v-model="params.name" style="width:200px" placeholder="请输入酒店名称"></el-input>
       <el-button type="primary" style="margin-left: 10px" @click="findBySreach()">查询</el-button>
       <el-button type="primary" style="margin-left: 10px" @click="reset()">清空</el-button>
       <el-button tyoe="warning" style="margin-left: 10px" @click="add()" v-if="user.role !== 'ROLE_STUDENT' ">新增</el-button> 
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%"> 
         <el-table-column  label="酒店图片" >
            <template v-slot="scope">
             <el-image 
              style="width: 50px; height: 50px;border-radius:50%"
              :src="$baseUrl +'/api/files/' + scope.row.img" 
              :preview-src-list="[$baseUrl +'/api/files/' + scope.row.img]">
             </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="酒店名称"></el-table-column>
          <el-table-column prop="price" label="酒店价格" ></el-table-column>  
          <el-table-column prop="num" label="剩余间数" ></el-table-column> 
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="edit(scope.row)" v-if="user.role === 'ROLE_ADMIN'">编辑</el-button>
              <el-button type="primary" v-if="user.role !== 'ROLE_ADMIN'" @click="reserve(scope.row)">预订</el-button>
              <el-popconfirm title="这是一段内容确定删除吗？" @confirm="del(scope.row.id)">
              <el-button slot="reference" type="danger" style="margin-left:5px">删除</el-button>
              </el-popconfirm>
            </template>
             
          </el-table-column> 
        </el-table>
      </div>
      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-sizes="[5, 10]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div>
        <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="30%">
          <el-form :model="form">
            <el-form-item label="酒店名称" :label-width="formLabelWidth">
              <el-input v-model="form.name" autocomplete="off" style="width:90%"></el-input>
            </el-form-item>

             <el-form-item label="酒店图片" :label-width="formLabelWidth">
              <el-upload :action="$baseUrl+"/api/files/upload" :on-success="successUpload">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="酒店价格" :label-width="formLabelWidth">
              <el-input v-model="form.price" autocomplete="off" style="width:90%"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit()" >确 定</el-button>
          </div>
  </el-dialog>

      </div>


  
   </div>   
  </template>



<script>
import request from "@/utils/request";

export default {
      name: "BookView",
      data() {
        return {
          params: {
            name: '',
            author: '',
            pageNum: 1,
            pageSize: 5
          },
          tableData: [],
          total: 0,
          dialogFormVisible: false,
          form: {},
          typeObjs: [],
          user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
        }
      },
      //页面加载的时候，做的一些事情，在created里面
      created(){
        this.findBySreach();
        //查询所有分类信息，下拉框
        this.findTypes();
      },
      //定义一些页面上空间触发的事件调用的方法
      methods: {
        findBySreach(){
            request.get("/hotel/search",{
              params:this.params
            }).then(res =>{
              if(res.code === '0'){
                this.tableData = res.data.list;
                this.total =res.data.total;
              }else{
                this.$message.error(res.msg)
              }
            })
          },
        add(){
          this.form = {},
          this.dialogFormVisible = true
        },
        submit(){
          request.post("/hotel",this.form).then(res => {
            if(res.code === '0'){
              this.$message.success('操作成功')
              this.dialogFormVisible = false;
              this.findBySreach();
            }else{
              this.$message.error(res.msg)
            }

          })
        },
        edit(obj){
          this.form = obj;
          this.dialogFormVisible = true;
        },
        del(id){
          console.log(id);
          request.delete("/hotel/"+ id).then(res => {
            if(res.code === '0'){
              this.$message.success('操作成功')
              this.findBySreach();
            }else{
             this.$message.error(res.msg)
            }
          })
        },
        reset(){
          this.params ={
            pageNum: 1,
            pageSize: 5,
            name: '',
            phone: '',
          }
          this.findBySreach();
        },
        handleSizeChange(pageSize){
          this.params.pageSize = pageSize;
          this.findBySreach();
        },
        handleCurrentChange(pageNum){
          this.params.pageNum = pageNum;
          this.findBySreach();

        },
        successUpload(res) {
          this.form.img = res.data

        },
        down(flag) {
          location.href = this.$baseUrl +'/api/files/' + flag
        },
        reserve(obj) {
          let param = {
           hotelId: obj.id,
           userId : this.user.id
          }
          request.post("/reserve" , param).then(res => {
            if(res.code === '0') {
              this.$message.success("预定成功")
            }else{
              this.$message.error(res.msg)
            }
          })

        },
        
      }
    }
  </script>
