import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//import request from "@/utils/request";


Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small', zIndex: 3000});

//Vue.prototype.$request = request
//Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL
//Vue.prototype.$baseUrl = "http://localhost:8089/api"
//Vue.prototype.$baseUrl = "http://43.143.214.173:8089/api"
Vue.prototype.$baseUrl = "https://www.lscarlive.com/api/api"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
