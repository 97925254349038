 <template>
   <div>
      <div>
       <el-input v-model="params.name" style="width:200px" placeholder="请输入图书名称"></el-input>
       <el-input v-model="params.phone" style="width:200px;margin-left:5px" placeholder="请输入图书作者"></el-input>
       <el-button type="primary" style="margin-left: 10px" @click="findBySreach()">查询</el-button>
       <el-button type="primary" style="margin-left: 10px" @click="reset()">清空</el-button>
       <el-button tyoe="warning" style="margin-left: 10px" @click="add()" v-if="user.role !== 'ROLE_STUDENT' ">新增</el-button> 
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%"> 
         <el-table-column  label="图书封面" >
            <template v-slot="scope">
             <el-image 
              style="width: 50px; height: 50px;border-radius:50%"
              :src="$baseUrl+ '/api/files/' + scope.row.img" 
              :preview-src-list="[$baseUrl+ '/api/files/' + scope.row.img]">
             </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="图书名称"></el-table-column>
          <el-table-column prop="author" label="图书作者"></el-table-column>
          <el-table-column  label="图书介绍">
          <template slot-scope="scope">
            <el-button type="success" @click="viewEditor(scope.row.content)">点击查看</el-button>
          </template>
          </el-table-column>
          <el-table-column prop="price" label="图书价格" ></el-table-column>  
          <el-table-column prop="typeName" label="图书分类" ></el-table-column>         
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <el-button type="primary" @click="edit(scope.row)">编辑</el-button>
              <el-button type="primary" @click="down(scope.row.img)">下载</el-button>
              <el-popconfirm title="这是一段内容确定删除吗？" @confirm="del(scope.row.id)">
              <el-button slot="reference" type="danger" style="margin-left:5px">删除</el-button>
              </el-popconfirm>
            </template>
             
          </el-table-column> 
        </el-table>
      </div>
      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-sizes="[10,20,100]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div>
        <el-dialog title="请填写信息" :visible.sync="dialogFormVisible" width="50%">
          <el-form :model="form">
            <el-form-item label="图书名称" label-width="15%">
              <el-input v-model="form.name" autocomplete="off" style="width:90%"></el-input>
            </el-form-item>

             <el-form-item label="图书封面" label-width="15%">
              <el-upload :action="$baseUrl+ '/api/files/upload'" :on-success="successUpload">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
              </el-upload>
            </el-form-item>

           <el-form-item label="图书作者" label-width="15%">
              <el-input v-model="form.author" autocomplete="off" style="width:90%"></el-input>
            </el-form-item>
            <el-form-item label="图书价格" label-width="15%">
              <el-input v-model="form.price" autocomplete="off" style="width:90%"></el-input>
            </el-form-item>
            <el-form-item label="图书出版社" label-width="15%">
              <el-input v-model="form.press" autocomplete="off" style="width:90%"></el-input>
            </el-form-item>
            <el-form-item label="图书分类" label-width="15%">
               <el-select v-model="form.typeId" placeholder="请选择"  style="width:90%">
                  <el-option
                    v-for="item in typeObjs"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="图书介绍" label-width="15%">
               <div id="editor"  style="width:90%"></div>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit()" >确 定</el-button>
          </div>
  </el-dialog>
      </div>

   <el-dialog title="图书介绍" :visible.sync="editorVisible" width="50%">
     <div v-html="this.form.content" class="w-e-text"></div>    
  </el-dialog>
  
   </div>   
  </template>



<script>
import request from "@/utils/request";
import E from 'wangeditor'

let editor
function initWangEditor(content) { setTimeout( ()=> {
  if(!editor) {
    editor = new E("#editor")
    editor.config.placeholder = "请输入内容"
    editor.config.uploadFileName = 'file'
    editor.config.uploadImgServer = this.$baseUrl+"/api/files/wang/upload"
    editor.create()
  }
  editor.txt.html(content)
},0)
}




export default {
      name: "BookView",
      data() {
        return {
          params: {
            name: '',
            author: '',
            pageNum: 1,
            pageSize: 10
          },
          tableData: [],
          total: 0,
          dialogFormVisible: false,
          editorVisible: false,
          form: {},
          typeObjs: [],
          user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
        }
      },
      //页面加载的时候，做的一些事情，在created里面
      created(){
        this.findBySreach();
        //查询所有分类信息，下拉框
        this.findTypes();
      },
      //定义一些页面上空间触发的事件调用的方法
      methods: {
        findTypes() {
          request.get("/type").then(res => {
            if(res.code === '0'){
              this.typeObjs = res.data;
              console.log(this.typeObjs)
              //this.$message.success('操作成功')
            }else{
             this.$message.error(res.msg)
            }
          })
        },
        findBySreach(){
            request.get("/book/search",{
              params:this.params
            }).then(res =>{
              if(res.code === '0'){
                this.tableData = res.data.list;
                this.total =res.data.total;
              }else{
                this.$message.error(res.msg)
              }
            })
          },
        add(){
          this.form = {},
          initWangEditor("");
          this.dialogFormVisible = true

        },
        submit(){
          this.form.content = editor.txt.html();
          request.post("/book",this.form).then(res => {
            if(res.code === '0'){
              this.$message.success('操作成功')
              this.dialogFormVisible = false;
              this.findBySreach();
            }else{
              this.$message.error(res.msg)
            }

          })
        },
        edit(obj){
          this.form =obj;
          //this.form =JSON.parse(JSON.stringify(obj));
          initWangEditor(obj.content ? this.form.content : "");

          this.dialogFormVisible = true;
        },
        del(id){
          console.log(id);
          request.delete("/book/"+ id).then(res => {
            if(res.code === '0'){
              this.$message.success('操作成功')
              this.findBySreach();
            }else{
             this.$message.error(res.msg)
            }
          })
        },
        reset(){
          this.params ={
            pageNum: 1,
            pageSize: 5,
            name: '',
            phone: '',
          }
          this.findBySreach();
        },
        handleSizeChange(pageSize){
          this.params.pageSize = pageSize;
          this.findBySreach();
        },
        handleCurrentChange(pageNum){
          this.params.pageNum = pageNum;
          this.findBySreach();

        },
        successUpload(res) {
          this.form.img = res.data

        },
        down(flag) {
          location.href = this.$baseUrl+'/files/' + flag
        },
        viewEditor(data){
          this.form.content=data;
          this.editorVisible =true;
        },
        
      }
    }
  </script>
